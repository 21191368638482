// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.archive-title
{
    text-align: center;
    color: white;
}

#archive-body
{
    display: grid;
    grid-template-columns: auto;
    grid-row-gap: 10px;
    position: relative;
    transform: translateX(-50%);
    left: 50%;
    justify-content: space-evenly;
    padding-bottom: 100px;
    background-color: black;
}`, "",{"version":3,"sources":["webpack://./src/pages/EventsPages/WorkshopArchive.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;;IAEI,aAAa;IACb,2BAA2B;IAC3B,kBAAkB;IAClB,kBAAkB;IAClB,2BAA2B;IAC3B,SAAS;IACT,6BAA6B;IAC7B,qBAAqB;IACrB,uBAAuB;AAC3B","sourcesContent":[".archive-title\n{\n    text-align: center;\n    color: white;\n}\n\n#archive-body\n{\n    display: grid;\n    grid-template-columns: auto;\n    grid-row-gap: 10px;\n    position: relative;\n    transform: translateX(-50%);\n    left: 50%;\n    justify-content: space-evenly;\n    padding-bottom: 100px;\n    background-color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
