// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Description Components */
.defaultspacing
{
    line-height: .6in;
}

#projectsmaindescription
{
    width: 50%;
    position: relative;
    left: 40px;
    color: rgb(88, 88, 88);
}

#projectmaindescriptiontitle
{
    font-size: 50px;
}

#projectmaindescriptionbody
{
    font-size: 25px;
}`, "",{"version":3,"sources":["webpack://./src/pages/EventsPages/Projects.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;;IAEI,iBAAiB;AACrB;;AAEA;;IAEI,UAAU;IACV,kBAAkB;IAClB,UAAU;IACV,sBAAsB;AAC1B;;AAEA;;IAEI,eAAe;AACnB;;AAEA;;IAEI,eAAe;AACnB","sourcesContent":["/* Description Components */\n.defaultspacing\n{\n    line-height: .6in;\n}\n\n#projectsmaindescription\n{\n    width: 50%;\n    position: relative;\n    left: 40px;\n    color: rgb(88, 88, 88);\n}\n\n#projectmaindescriptiontitle\n{\n    font-size: 50px;\n}\n\n#projectmaindescriptionbody\n{\n    font-size: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
