// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#members-body
{
    background-color: black;
}

#board-banner-contents
{
    position: relative;
}

#board-banner
{
    width: 100%;
}

#board-shadow
{
    z-index: 1;
    position: absolute;
    bottom: -1rem;
    width: 100%;
    height: 35%;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1) 80%);
}

#board-content
{
    padding-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/BoardMembers/Members.css"],"names":[],"mappings":"AAAA;;IAEI,uBAAuB;AAC3B;;AAEA;;IAEI,kBAAkB;AACtB;;AAEA;;IAEI,WAAW;AACf;;AAEA;;IAEI,UAAU;IACV,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,WAAW;IACX,8EAA8E;AAClF;;AAEA;;IAEI,iBAAiB;AACrB","sourcesContent":["#members-body\n{\n    background-color: black;\n}\n\n#board-banner-contents\n{\n    position: relative;\n}\n\n#board-banner\n{\n    width: 100%;\n}\n\n#board-shadow\n{\n    z-index: 1;\n    position: absolute;\n    bottom: -1rem;\n    width: 100%;\n    height: 35%;\n    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1) 80%);\n}\n\n#board-content\n{\n    padding-top: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
