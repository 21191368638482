// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workshop-archive-card
{
    position: absolute;
}

.workshop-archive-text
{
    color: white;
    margin: 0;
    cursor: pointer;
}

.workshop-archive-text:hover
{
    color: orange;
}

.folder-top
{
    position: absolute;
    top: -2.45vw;
}

.folder-top-img
{
    width: 20vw;
}

.folder-body
{
    position: relative;
    background-color: blanchedalmond;
    width: 50vw;
    height: 25vw;
}

.workshop-archive-title
{
    padding-top: 2vw;
    text-align: center;
    font-size: 2vw;
}

.workshop-archive-description
{
    padding-left: 3vw;
    padding-right: 3vw;
    line-height: 2vw;
    text-align: center;
    font-size: 1.3vw;
}

.workshop-archive-thumbnail
{
    position: relative;
    transform: translateX(-50%);
    left: 50%;
    width: 20vw;
    height: 10vw;
}

.hidden
{
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/WorkshopArchiveCard.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;AACtB;;AAEA;;IAEI,YAAY;IACZ,SAAS;IACT,eAAe;AACnB;;AAEA;;IAEI,aAAa;AACjB;;AAEA;;IAEI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;;IAEI,WAAW;AACf;;AAEA;;IAEI,kBAAkB;IAClB,gCAAgC;IAChC,WAAW;IACX,YAAY;AAChB;;AAEA;;IAEI,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;;IAEI,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;;IAEI,kBAAkB;IAClB,2BAA2B;IAC3B,SAAS;IACT,WAAW;IACX,YAAY;AAChB;;AAEA;;IAEI,aAAa;AACjB","sourcesContent":[".workshop-archive-card\n{\n    position: absolute;\n}\n\n.workshop-archive-text\n{\n    color: white;\n    margin: 0;\n    cursor: pointer;\n}\n\n.workshop-archive-text:hover\n{\n    color: orange;\n}\n\n.folder-top\n{\n    position: absolute;\n    top: -2.45vw;\n}\n\n.folder-top-img\n{\n    width: 20vw;\n}\n\n.folder-body\n{\n    position: relative;\n    background-color: blanchedalmond;\n    width: 50vw;\n    height: 25vw;\n}\n\n.workshop-archive-title\n{\n    padding-top: 2vw;\n    text-align: center;\n    font-size: 2vw;\n}\n\n.workshop-archive-description\n{\n    padding-left: 3vw;\n    padding-right: 3vw;\n    line-height: 2vw;\n    text-align: center;\n    font-size: 1.3vw;\n}\n\n.workshop-archive-thumbnail\n{\n    position: relative;\n    transform: translateX(-50%);\n    left: 50%;\n    width: 20vw;\n    height: 10vw;\n}\n\n.hidden\n{\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
